import React, { useContext, useState } from 'react'
import { Link, navigate } from 'gatsby'
import { LanguageContext } from '../../context'
import { homePagePaths, translate } from '../../translate'
import Modal from "../modal"
import ToastMessage from "../toast"
import { useToasts } from "react-toast-notifications"

const ButtonVolunteer = (props) => {

  const { children } = props

  const { t } = useContext(LanguageContext)
  const { lang, backgroundimage } = props


  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)

  const [modalShow, setModalShow] = useState(false);
  const [messageShow, setMessageShow] = useState(false);

  const modalFooter = () => {
    return(
      <>
        <p>{t("Si necessites ajuda, posa’t en contacte amb nosaltres mitjançant el número de telèfon (+34) 937 88 16 61, de dilluns a diumenge de 9:00 a 17:00, o envia’ns un correu electrònic a info@fundaciotramuntana.es.")}</p>
      </>
    )
  }

  const sendMessage = (response) => {
    setModalShow(false)
    setMessageShow(true);
  }

  const { addToast } = useToasts()

  const {title=t("Fer voluntariat"), className="btn btn-outline btn-outline-visit-us my-2 my-sm-0"} = props

  return (
   <>
     <div
     className="col-md-12 call-to-action">
       <div className="row">
         <div className="col-md-12 p-0">
           <button
           className={className}
           type="submit"
           onClick={() => setModalShow(true)}
           >{title}</button>
         </div>
       </div>
     </div>

     <ToastMessage
      show={messageShow}
      title={t("Dades enviades")}
      message={t("Dades enviades correctament. Aviat es posarà en contacte amb tu una persona de la Tramuntana. Gràcies!")}
      onClose={() => setMessageShow(false)}
      position="top-center"
      lang={props?.lang}
      />
     <Modal
       title={t("Vols fer voluntariat?")}
       description={t("Omple el següent formulari especificant quin voluntariat t'agradaria fer.")}
       show={modalShow}
       onHide={() => setModalShow(false)}
       backgroundimage={backgroundimage}
       size='lg'
       className="modal-unida"
       theform='voluntariat_tramuntana'
       footer={modalFooter()}
       sendMessage={(response) => sendMessage(response)}
       lang={props?.lang}
       id="modal-volunteer"
       children={children}
       />
   </>
 )
}

export default ButtonVolunteer
